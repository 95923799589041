// @codekit-prepend "../bower_components/jquery/dist/jquery.min.js";
// @codekit-prepend "../bower_components/foundation-sites/dist/js/foundation.min.js";
// @codekit-prepend "siema.min.js";

$(document).foundation();

$(document).ready(function(){

	// home page image carousel
	if (document.querySelector('.slideshow__slides')) {
		const mySiemaOne = new Siema({
	  	perPage: 2,
	  	loop: true,
	  	rtl: true,
	  	selector: '.slideshow__slides',
		});
		const prev = document.querySelector('.prev');
		const next = document.querySelector('.next');

		prev.addEventListener('click', () => mySiemaOne.prev());
		next.addEventListener('click', () => mySiemaOne.next());
	}

	// service boxes carousel
	if (document.querySelector('.carousel__slides')) {
		const mySiemaTwo = new Siema({
	  	perPage: {
	     768: 2,
	     1024: 3,
	    },
	  	loop: true,
	  	selector: '.carousel__slides',
		});
		const back = document.querySelector('.back');
		const forw = document.querySelector('.forw');

		back.addEventListener('click', () => mySiemaTwo.prev());
		forw.addEventListener('click', () => mySiemaTwo.next());
	}

});